import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import Axios from 'axios'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { updateListing } from '../../../services/api/mutationService';

const ProductFiles =({ selectData, productData, refetch, update, activeMarketplace }) => {

  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  //const _uri = process.env.REACT_APP_ENV === 'prod_azure' ? 'https://azure-api.ebazaaris.io' : 'https://staging-api.ebazaaris.io';
  // const _uri = 'http://localhost:8000';

  let _uri = 'https://staging-api.ebazaaris.io'
  if (process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'prod_azure') {
    _uri = 'https://azure-api.ebazaaris.io'
  } else if (process.env.REACT_APP_ENV === 'dev') {
    _uri = 'http://localhost:8000'
  }

  const fileInputs = [];
  let files = []

  const calcSize = size => {
    var _size = size;
    var fSExt = ['Bytes', 'Kb', 'Mb', 'Gb'],
    i=0; while(_size>900){_size/=1024;i++;}
    var exactSize = (Math.round(_size*100)/100)+' '+fSExt[i];
    return exactSize;
  }

  for(let i = 0; i < 10; i++){
    files[i] = files[i] ? files[i] :
    {
      listing_id: productData.id,
      src: null,
      updated: false,
      file: null,
      isImage: false,
      error: null
    }

    fileInputs[i] = {
      d: React.createRef()
    }
  }

  productData.files.map(item => {
    files[item.position-1] = {
      listing_id: productData.id,
      src: item.filename,
      updated: false,
      file: {
        size: item.size
      },
      isImage: item.filename.split('/')[item.filename.split('/').length-1].split('.')[1].toLowerCase() !== 'pdf',
      error: null
    }
  })

  const [formData, setFormData] = useState([...files]);

  const handleOndragOver = event => {
    event.preventDefault();
  }
  const handleOndrop = event => {
      //prevent the browser from opening the image
      event.preventDefault();
      event.stopPropagation();
      //let's grab the image file
      let imageFile = event.dataTransfer.files[0];
      handleFile(imageFile, +event.target.id);

  }

  const handleFile = (files, index) => {
    console.log(files, index)
    for(let i = 0; i < files.length; i++){
      while(formData[index]){
        console.log(!formData[index].file)
        if(!formData[index].file){
          formData[index] = {
            listing_id: productData.id,
            src: URL.createObjectURL(files[i]),
            file: files[i],
            updated: true,
            isImage: files[i].name.split('.')[1].toLowerCase() !== 'pdf',
            // error: files[i].size > 2000000 ? 'File should be less than 2MB' : null,
            error: null,
            id: index+1
          }
          break;
        }

        index++;
      }
    }

    setFormData([...formData])
  }

  useEffect(() => {
    files = []

    for(let i = 0; i < 10; i++){
      files[i] = files[i] ? files[i] :
      {
        listing_id: productData.id,
        src: null,
        updated: false,
        file: null,
        isImage: false,
        error: null,
        id: i+1
      }
    }

    productData.files.map((item, i) => {
      files[item.position-1] = {
        listing_id: productData.id,
        src: item.filename,
        updated: false,
        file: {
          size: item.size
        },
        isImage: item.filename.split('/')[item.filename.split('/').length-1].split('.')[1].toLowerCase() !== 'pdf',
        error: item.error,
        id: item.position
      }
    })

    setFormData([...files])
  }, [productData])

  const submitHandler = async event => {
    event.preventDefault();

    setLoading(true);

    const fData = new FormData();
    formData.forEach((x, i) => {
      if(x.error === null) fData.append(`file-${i}`, x.file)
    })

    fData.append('pid', productData.product_parent_id)
    fData.append('id', productData.id)
    setFormData([])

    Axios.post(`${_uri}/api/file-upload`, fData)
    .then(res =>{
      const response = res.data.files
      const fetchData = []
      for(let i = 0; i < 10; i++){
        fetchData[i] = {
          listing_id: productData.id,
          src: null,
          updated: false,
          file: null,
          isImage: false,
          error: null
        }
      }

      response.forEach((x, i) => {
        if(x.listing_id == productData.id){
          fetchData[x.position-1] = {
            listing_id: x.listing_id,
            src: `https://documentsupload.blob.core.windows.net/pictures/${x.filename}`,
            updated: false,
            file: {
              size: x.size
            },
            isImage: x.filename.split('/')[x.filename.split('/').length-1].split('.')[1].toLowerCase() !== 'pdf',
            error: x.error
          }
        }

      })

      setFormData([...fetchData])

      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });

      refetch();
      setLoading(false);

    }).catch(e => {
      setError(e);
    })
  };

  const deleteHandler = async event => {
    event.preventDefault();

    setLoading(true);
    const fData = new FormData();
    const position = []
    selectedItems.forEach((x, i) => {
      position.push(x+1)
    })
    fData.append('position', position)
    fData.append('pid', productData.product_parent_id)
    fData.append('activeMarketplace', activeMarketplace)
    setFormData([])

    Axios.post(`${_uri}/api/delete-files`, fData)
    .then(res =>{
      const response = res.data.files

      const fetchData = []
      for(let i = 0; i < 10; i++){
        fetchData[i] = {
          listing_id: productData.id,
          src: null,
          updated: false,
          file: null,
          isImage: false,
          error: null
        }
      }
      response.forEach((x, i) => {
        fetchData[x.position-1] = {
          listing_id: x.listing_id,
          src: `https://documentsupload.blob.core.windows.net/pictures/${x.filename}`,
          updated: false,
          file: {
            size: x.size
          },
          isImage: x.filename.split('/')[x.filename.split('/').length-1].split('.')[1].toLowerCase() !== 'pdf',
          error: x.error
        }
      })



      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
      setLoading(false);
      setFormData([...fetchData])
      setSelectedItems([]);


    }).catch(e => {
      setError(e);
    })
  }

  const checkboxClickHandler = (item, event) => {
    let updatedSelectedItems = [...selectedItems];

    if (event.target.checked) {
      updatedSelectedItems.push(+event.target.id);
    } else {
      updatedSelectedItems = updatedSelectedItems.filter(i => i !== +event.target.id);
    }

    updatedSelectedItems.sort((a, b) => b - a);
    setSelectedItems(updatedSelectedItems);
  };

  const changeFieldHandler = event => {
    files[event.target.id] = event.target.value
    files.filter(n => n)
    setFormData({
      ...formData,
      ['files']: files.join(';')
    });
  };

  const handleOnDragEnd = result => {
  }


  const grid = 12;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
  });

  const getListStyle = isDraggingOver => ({
    display: 'flex',
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      formData,
      result.source.index,
      result.destination.index
    );

    const fData = []
    items.map((x, i) => {
      if(x.src){
        const p = {
          file: x.src,
          position: i+1
        }

        fData.push(p)
      }
    })

    Axios.post(`${_uri}/api/files-sort`, {data: fData})
    .then(res => {
      console.log(res)
    }).catch(e => {
      console.log(e)
    })

    setFormData([...items])
  }

  if (error) {
    throw new Error(error);
  }

  return (
    <form onSubmit={submitHandler}>
      <div className="row row-cols-4">
      <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div className="col-12 d-flex overflow-auto" ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}>
            {formData && formData.map((item, index) => item.listing_id == productData.id && (
              <Draggable isDragDisabled={true} key={item.id} draggableId={item.id + ""} index={index}>
                  {(provided, snapshot) => (
                <div className="listing-image" key={index} ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                  >
                  <label htmlFor="">{item.src ? item.src.split('/')[item.src.split('/').length-1] : `File ${index+1}`}</label>
                  <div className="image-box">
                    {item && item.src ? (
                      <div className="image-prev">
                        <img className={ item.isImage ? '' : 'sm-f-icon'} src={item.src && item.isImage ? ( item.updated ? item.src : `https://documentsupload.blob.core.windows.net/pictures/${item.src}`) : '/assets/images/pdf-file.svg'} id={index} alt=""/>
                        <input
                          type="checkbox"
                          className="checkbox form-check-input"
                          id={index}
                          checked={selectedItems.some(i => +i === +index)}
                          onChange={checkboxClickHandler.bind(null, item)}
                        />
                        <div className="drag-image">
                          <img src="/assets/images/drag.svg" alt=""/>
                        </div>
                        <div className="expand-image" onClick={() => window.open(item.updated ? item.src : `https://documentsupload.blob.core.windows.net/pictures/${item.src}`)}>
                          <img src="/assets/images/expand.svg"  alt=""/>
                        </div>
                      </div>
                    ) : (
                      <div className="image-prev upload-image"
                        id={index}
                        onDragOver = {handleOndragOver}
                        onDrop = {handleOndrop}
                        onClick = { () => fileInputs[index].d.current.click() }>
                        <img src={'/assets/images/upload.svg'} id={index} alt=""/>
                        <p>Drop file here or click to upload</p>
                        <input
                          type="file"
                          accept='.jpeg,.jpg,.pdf'
                          multiple={true}
                          ref={fileInputs[index].d} hidden
                          onChange={e => handleFile(e.target.files, index)}
                          />
                      </div>
                    )}

                    <div className="img-btn">
                      <button type="button" onClick={() => {navigator.clipboard.writeText(item.updated ? item.src : `https://documentsupload.blob.core.windows.net/pictures/${item.src}`)}} className="btn btn-secondary btn-sm" disabled={item.src ? '' : 'disabled'}>
                        Copy URL
                      </button>
                      {item && item.src && (
                        <span className="text-grey">Size: {calcSize(item.file.size)}</span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              </Draggable>
            ))}
            {provided.placeholder}
            </div>
          )}
          </Droppable>
        </DragDropContext>
      </div>
      {update && (
        <>
          {loading ? (
            <div className="spinner">Spinner</div>
          ) : (
            <>
            <button type="submit" className="btn btn-primary px-3 mt-3 mr-3">
              Save
            </button>
            {selectedItems.length > 0 && (
              <button type="button" className="btn btn-danger px-3 mt-3" onClick={deleteHandler}>
                Delete Document{selectedItems.length > 1 ? 's' : ''}
              </button>
            )}
            </>
          )}
        </>
      )}
    </form>
  );
};

export default ProductFiles;
