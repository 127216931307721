import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';

import Table from '../../components/table/Table';
import { queryStringHandler, fetchData, createDefaultOption, convertChannelToOptions, convertChannelToOption } from '../../services/util/dataHandlerService';
import { getProducts } from '../../services/api/queryService';
import Error from '../../components/modal/ModalError';
import Select from 'react-select';
import { deleteManufacturerAds, updateManufacturer } from '../../services/api/mutationService';

import "./manufacturer.scss";

const ManufacturerSettings = ({ data, channels, refetch, update }) => {
    const dispatch = useDispatch();
    const [validationErrror, setValidationError] = useState();
    const [commissionRequired, setCommissionRequired] = useState(data.commission_type === 'Goods consignment' ?  true :  false);
    const [commissionDisabled, setCommissionDisabled] = useState(data.commission_type === 'Goods consignment' ?  false :  true);
    const [selectedItems, setSelectedItems] = useState([]);
    const [invalidForm, setInvalidForm] = useState(false)
    const [ppcData, setPpcData] = useState(data.manufacturerAdvertisingInforamtions.filter(x => x.advertising_type.trim() == 'ppc'))
    const [dspData, setDspData] = useState(data.manufacturerAdvertisingInforamtions.filter(x => x.advertising_type.trim() == 'dsp'))
    const [apnData, setApnData] = useState(data.manufacturerAdvertisingInforamtions.filter(x => x.advertising_type.trim() == 'apn'))
  

    useEffect(() => {
        const ppc = data.manufacturerAdvertisingInforamtions.filter(x => x.advertising_type.trim() == 'ppc')
        const dsp = data.manufacturerAdvertisingInforamtions.filter(x => x.advertising_type.trim() == 'dsp')
        const apn = data.manufacturerAdvertisingInforamtions.filter(x => x.advertising_type.trim() == 'apn')

        setPpcData(ppc)
        setDspData(dsp)
        setApnData(apn)

        
    }, [data, refetch])

    const [formData, setFormData] = useState({
        id: data.id,
        commission_type: data.commission_type,
        commission_percentage: data.commission_percentage,
        name: data.name,
        status: data.status,
        advertising_status: data.advertising_status,
        advertising_channel_id: data.advertising_channel_id,
        alt_name: data.alt_name,
        type: data.type,
        service_type: data.service_type,
        allDspChecked: false,
        allPpcChecked: false,
        allApnChecked: false,
        brands: []
    });
    const [error, setError] = useState();
    const commissions = [
        { label: 'Goods consignment', value: 'Goods consignment' },
        { label: 'Goods purchase', value: 'Goods purchase' },
        { label: 'No commission', value: 'No commission' }
    ]
    const statuses = [
        { label: 'Active', value: 1 },
        { label: 'Archived', value: 0 }
    ]

    const types = [
        { label: 'EMS', value: 1 },
        { label: 'APS', value: 2 }
    ]

    const commission_types = [
        { label: 'Fixed amount', value: 1 },
        { label: 'Fixed budget', value: 3 },
        { label: 'Percentage', value: 2 }
    ]

    const service_types = [
        { label: 'Acceleration', value: 'Acceleration' },
        { label: 'Service', value: 'Service' },
        { label: 'Service Plus', value: 'Service Plus' }
    ]

    const [updateManufacturerMutation, {loading}] = useMutation(
        updateManufacturer
    );

    const [deleteManufacturerAdsMutation, {loading: deleteLoading}] = useMutation(
        deleteManufacturerAds
    );
    
    

    const changeFieldHandler = event => {
      setFormData({
        ...formData,
        [event.target.id]: event.target.value
      });
    };

    const pushNewItem = type => {
        if(type == 'dsp'){
            const dsp = [...dspData]
            dsp.push({
                id: Math.floor(Math.random() * 99999) + 99999,
                advertising_type: 'dsp',
                from_ad_spend: 0,
                to_ad_spend: 0,
                commission_type: 1,
                value: '',
                errors: []
            })

            setDspData([...dsp])
        }else if(type == 'ppc'){
            const ppc = [...ppcData]
            ppc.push({
                id: Math.floor(Math.random() * 999999) + 99999,
                advertising_type: 'ppc',
                from_ad_spend: 0,
                to_ad_spend: 0,
                commission_type: 1,
                value: '',
                errors: []
            })
            setPpcData([...ppc])
        }else{
            const apn = [...apnData]
            apn.push({
                id: Math.floor(Math.random() * 999999) + 99999,
                advertising_type: 'apn',
                from_ad_spend: 0,
                to_ad_spend: 0,
                commission_type: 1,
                value: '',
                errors: []
            })
            setApnData([...apn])
        }

        setInvalidForm(true)
    }
    
    const changeSelectHandler = (option, data) => {  
        if(data.name === 'commission_type'){
            if(option.value == 'Goods consignment'){
                setCommissionDisabled(false)
                setCommissionRequired(true)
            }else{
                setCommissionRequired(false)
                setCommissionDisabled(true)
            }
        }
        setFormData({
          ...formData,
          [data.name]: option && option.value
        });
    };

    const submitHandler = async event => {
        event.preventDefault();

        try {
            delete formData.__typename
            delete formData.error

            const res = await updateManufacturerMutation({
              variables: {
                name: formData.name,
                brands: formData.brands,
                alt_name: formData.alt_name,
                commission_percentage: formData.commission_percentage,
                commission_type: formData.commission_type,
                status: formData.status,
                advertising_status: formData.advertising_status,
                advertising_channel_id: +formData.advertising_channel_id,
                type: formData.type,
                service_type: formData.service_type,
                id: formData.id,
                ppc: JSON.stringify(ppcData),
                dsp: JSON.stringify(dspData),
                apn: JSON.stringify(apnData),
              },
              id: formData.id
            });
    
            if (!data.id) {
              throw new Error('Server responded with no data');
            }
    
            const errorMessage = res.data.updateManufacturer.error;

            console.log('errorMessage', res.data.updateManufacturer.error)
            console.log('errorMessage', errorMessage)
            
            if (errorMessage) {

                const err = JSON.parse(errorMessage)

                if(!err.error){
                    const ppc = err.filter(x => x.advertising_type.trim() == 'ppc')
                    const dsp = err.filter(x => x.advertising_type.trim() == 'dsp')
                    const apn = err.filter(x => x.advertising_type.trim() == 'apn')
                    
                    setPpcData(ppc)
                    setDspData(dsp)
                    setApnData(apn)
                }

                dispatch({
                    type: 'UPDATE',
                    payload: {
                      type: 'error',
                      message: `There are some unsolved errors: ${err.error}`
                    }
                });  

                return;
            }
          
            if (!data.id) {
              throw new Error('Server responded with no data');
            }
    
        } catch (err) {
          setError('Internal server error');
        }
    
        dispatch({
          type: 'UPDATE',
          payload: {
            type: 'success',
            message: `Recored was successfully updated`
          }
        });  

        refetch()

    }

    const deleteHandler = async event => {
        event.preventDefault();

        try{
            const res = await deleteManufacturerAdsMutation({
                variables: { id: selectedItems }
            })

        }catch(err){
          setError('Internal server error');

        }
        
        dispatch({
            type: 'UPDATE',
            payload: {
              type: 'success',
              message: `Recored was successfully deleted`
            }
        });  
  
        refetch()

    }

    const allCheckboxClickHandler = type => {
        let updatedSelectedItems = [...selectedItems];
        const fd = {...formData}

        if(type == 'dsp'){
            fd.allDspChecked = !fd.allDspChecked
            dspData.map(x => {

                if(fd.allDspChecked){
                    updatedSelectedItems.push(x.id)
                }else{
                    const ind = updatedSelectedItems.indexOf(x.id)
                    updatedSelectedItems.splice(ind, 1)
                }

            })
        }else if(type == 'ppc'){
            fd.allPpcChecked = !fd.allPpcChecked
            ppcData.map(x => {
                if(fd.allPpcChecked){
                    updatedSelectedItems.push(x.id)
                }else{
                    const ind = updatedSelectedItems.indexOf(x.id)
                    updatedSelectedItems.splice(ind, 1)
                }
            })
        }else{
            fd.allApnChecked = !fd.allApnChecked
            ppcData.map(x => {
                if(fd.allApnChecked){
                    updatedSelectedItems.push(x.id)
                }else{
                    const ind = updatedSelectedItems.indexOf(x.id)
                    updatedSelectedItems.splice(ind, 1)
                }
            })
        }

        updatedSelectedItems = [...new Set(updatedSelectedItems)]

        setFormData({...fd})
        setSelectedItems(updatedSelectedItems);
    }

    const checkboxClickHandler = (item, event) => {
    
        let updatedSelectedItems = [...selectedItems];
    
        if (event.target.checked) {
          updatedSelectedItems.push(item.id);
        } else {
          updatedSelectedItems = updatedSelectedItems.filter(i => i !== item.id);
        }
    
        updatedSelectedItems.sort((a, b) => b - a);
        setSelectedItems(updatedSelectedItems);
    };

    const changePPCFieldHandler = (rowNumber, event) => {
        const updatedFormData = [...ppcData];
    
        if(event.target.id == 'value'){
            let n = event.target.value.replaceAll('%', '')
            console.log(n)
            if(updatedFormData[rowNumber].commission_type == 1){
                if(isNaN(n)){
                    n = updatedFormData[rowNumber].value
                }

                n = Math.floor(n)
            }else{
                if(n == updatedFormData[rowNumber].value){
                    n = n.slice(0, -1)
                }
            }

            updatedFormData[rowNumber] = {
                ...updatedFormData[rowNumber],
                [event.target.id]: n
            };
        }else{
            updatedFormData[rowNumber] = {
                ...updatedFormData[rowNumber],
                [event.target.id]: event.target.value
            };
        }


        if(updatedFormData[rowNumber].errors && updatedFormData[rowNumber].errors[event.target.id]){
            delete updatedFormData[rowNumber].errors[event.target.id]
        }

        setPpcData(updatedFormData);
    };
    
    const changeDSPFieldHandler = (rowNumber, event) => {
        const updatedFormData = [...dspData];

        if(event.target.id == 'value'){
            let n = event.target.value.replaceAll('%', '')
            console.log(n)
            if(updatedFormData[rowNumber].commission_type == 1){
                if(isNaN(n)){
                    n = updatedFormData[rowNumber].value
                }

                n = Math.floor(n)
            }else{
                if(n == updatedFormData[rowNumber].value){
                    n = n.slice(0, -1)
                }
            }

            updatedFormData[rowNumber] = {
                ...updatedFormData[rowNumber],
                [event.target.id]: n
            };
        }else{
            updatedFormData[rowNumber] = {
                ...updatedFormData[rowNumber],
                [event.target.id]: event.target.value
            };
        }

        
        if(updatedFormData[rowNumber].errors && updatedFormData[rowNumber].errors[event.target.id]){
            delete updatedFormData[rowNumber].errors[event.target.id]
        }

        setDspData(updatedFormData);
    };

    const changeApnFieldHandler = (rowNumber, event) => {
        const updatedFormData = [...apnData];

        if(event.target.id == 'value'){
            let n = event.target.value.replaceAll('%', '')
            console.log(n)
            if(updatedFormData[rowNumber].commission_type == 1){
                if(isNaN(n)){
                    n = updatedFormData[rowNumber].value
                }

                n = Math.floor(n)
            }else{
                if(n == updatedFormData[rowNumber].value){
                    n = n.slice(0, -1)
                }
            }

            updatedFormData[rowNumber] = {
                ...updatedFormData[rowNumber],
                [event.target.id]: n
            };
        }else{
            updatedFormData[rowNumber] = {
                ...updatedFormData[rowNumber],
                [event.target.id]: event.target.value
            };
        }

        
        if(updatedFormData[rowNumber].errors && updatedFormData[rowNumber].errors[event.target.id]){
            delete updatedFormData[rowNumber].errors[event.target.id]
        }

        setApnData(updatedFormData);
    };

    const changePPCSelectHandler = rowNumber => (option, data) => {
        const updatedFormData = [...ppcData];

        updatedFormData[rowNumber] = {
          ...updatedFormData[rowNumber],
          [data.name]: option ? option.value : undefined
        };

        setPpcData(updatedFormData);
    }

    const changeDSPSelectHandler = rowNumber => (option, data) => {
        const updatedFormData = [...dspData];

        updatedFormData[rowNumber] = {
          ...updatedFormData[rowNumber],
          [data.name]: option ? option.value : undefined
        };

        setDspData(updatedFormData);
    }

    const changeApnSelectHandler = rowNumber => (option, data) => {
        const updatedFormData = [...apnData];

        updatedFormData[rowNumber] = {
          ...updatedFormData[rowNumber],
          [data.name]: option ? option.value : undefined
        };

        setApnData(updatedFormData);
    }


    return (
        <>
        {!data ? (
            <div className="spinner absolute">Spinner</div>
        ) : (
            <div className="row">
                <div className="col-md-12 ">
                    <Error setError={() => setValidationError('')} error={validationErrror}  close={true} />

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="pl-2 mt-0">General Information</h4>
                                    <hr />
                                    <div style={{'display': 'flex', 'flexWrap': 'wrap'}}>
                                        <div className="col-3 mb-3">
                                            <label className="mb-1 mr-2">
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="form-control"
                                                value={formData.name}
                                                onChange={changeFieldHandler}
                                                required={true}
                                            />
                                        </div>

                                        <div className="col-3">
                                            <label className="mb-1 mr-2">
                                            Short name
                                            </label>
                                            <input
                                                type="text"
                                                id="alt_name"
                                                name="alt_name"
                                                className="form-control"
                                                value={formData.alt_name}
                                                onChange={changeFieldHandler}
                                                required={true}
                                            />
                                        </div>

                                        <div className="col-3">
                                            <label className="mb-1 mr-2">
                                                Company type
                                            </label>
                                            <Select
                                                className="w-100"
                                                id="type"
                                                name="type"
                                                value={types.filter(x => x.value == formData.type)[0]}
                                                onChange={changeSelectHandler}
                                                options={types}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <label className="mb-1 mr-2">
                                                Service type
                                            </label>
                                            <Select
                                                className="w-100"
                                                id="service_type"
                                                name="service_type"
                                                value={service_types.find(x => x.value == formData.service_type)}
                                                onChange={changeSelectHandler}
                                                options={service_types}
                                            />
                                        </div>
                                    </div>
                            
                                    {update && (
                                        <div className="form-group col-12 d-flex pt-1 mt-2">
                                            <button type="submit" onClick={submitHandler} className="btn btn-primary custom-btn mr-1 mb-1" >Save</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                            <div className="col-12">
                                    <h4 className="pl-2 mt-0">Sales Information</h4>
                                    <hr />
                                        <div style={{'display': 'flex', 'flexWrap': 'wrap'}}>
                                            
                                        <div className="col-3 mb-3">
                                            <label className="mb-1 mr-2 d-flex align-items-center">
                                                Commission type
                                                <div className="m_infoButton CTinfoButton">
                                                    <img src="/assets/images/info-button.png" alt="" />
                                                </div>
                                            </label>
                                            <Select
                                                className="w-100"
                                                id="commission_type"
                                                name="commission_type"
                                                value={commissions.filter(x => x.value == formData.commission_type)[0]}
                                                onChange={changeSelectHandler}
                                                options={commissions}
                                            />
                                        </div>

                                        <div className="col-3 mb-3">
                                            <label className="mb-1 mr-2 d-flex align-items-center">
                                                Commission (in %)
                                                <div className="m_infoButton CPinfoButton">
                                                    <img src="/assets/images/info-button.png" alt="" />
                                                </div>
                                            </label>
                                            <input
                                                type="text"
                                                id="commission_percentage"
                                                name="commission_percentage"
                                                className="form-control"
                                                value={commissionDisabled ? 0 : formData.commission_percentage}
                                                onChange={changeFieldHandler}
                                                required={commissionRequired}
                                                disabled={commissionDisabled}
                                            />
                                        </div>

                                        <div className="col-3 mb-3 statusSelect">
                                            <label className="mb-1 mr-2">
                                                Sales status
                                            </label>
                                            <Select
                                                className="w-100"
                                                id="status"
                                                name="status"
                                                value={statuses.filter(x => x.value == formData.status)[0]}
                                                onChange={changeSelectHandler}
                                                options={statuses}
                                            />
                                        </div>

                                    </div>
                            
                                    {update && (
                                        <div className="form-group col-12 d-flex pt-1 mt-2">
                                            <button type="submit" onClick={submitHandler} className="btn btn-primary custom-btn mr-1 mb-1" >Save</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">                                
                                <div className="col-12">
                                    <h4 className="pl-2 mt-0">Advertising Information</h4>
                                    <hr />
                                    <div style={{'display': 'flex', 'flexWrap': 'wrap'}}>
                                        <div className="col-3 mb-3 statusSelect">
                                            <label className="mb-1 mr-2">
                                                Advertising status
                                            </label>
                                            <Select
                                                className="w-100"
                                                id="advertising_status"
                                                name="advertising_status"
                                                value={statuses.filter(x => x.value == formData.advertising_status)[0]}
                                                onChange={changeSelectHandler}
                                                options={statuses}
                                            />
                                        </div>
                                    </div>

                                    <div className='form-group col-12 pt-1 mt-2'>
                                        <label className="mb-1 mr-2">
                                            PPC
                                        </label>
                                        <table className="table DIN2014 mt-0">
                                            <thead>
                                                <tr>
                                                    <th style={{width: "40px"}} className='text-center align-middle'>
                                                        <input
                                                            type="checkbox"
                                                            checked={!!formData.allPpcChecked}
                                                            onChange={() => allCheckboxClickHandler('ppc')}
                                                        />
                                                    </th>
                                                    <th style={{width: "200px"}}>
                                                        From ad spend.....
                                                    </th>
                                                    <th style={{width: "200px"}}>
                                                        To ad spend.....
                                                    </th>
                                                    <th style={{width: "200px"}}>
                                                    Commission type
                                                    </th>
                                                    <th style={{width: "200px"}}>
                                                    Value
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ppcData.map((row, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center align-middle">
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedItems.some(i => i === row.id)}
                                                                onChange={checkboxClickHandler.bind(null, row)}
                                                            />
                                                        </td>
                                                        <td style={{ position: 'relative', overflow: 'visible' }}>
                                                            <input
                                                                className={`form-control ${ row.errors?.from_ad_spend ? 'invalid' : ''}`}
                                                                type="number"
                                                                step={0.01}
                                                                id='from_ad_spend'
                                                                onChange={event => changePPCFieldHandler(index, event)}
                                                                value={row.from_ad_spend}
                                                            />
                                                            { row.errors?.from_ad_spend && (
                                                                <div className="custom_error_container" >
                                                                    <img src="/assets/images/info-button.png" alt="" />
                                                                    <p>The amount should be higher then {+ppcData[index-1].to_ad_spend}</p>
                                                                </div>
                                                            ) }
                                                        </td>
                                                        <td style={{ position: 'relative', overflow: 'visible' }}>
                                                            <input
                                                                className={`form-control ${ row.errors?.to_ad_spend ? 'invalid' : ''}`}
                                                                type="number"
                                                                step={0.01}
                                                                id='to_ad_spend'
                                                                onChange={event => changePPCFieldHandler(index, event)}
                                                                value={row.to_ad_spend}
                                                            />
                                                            { row.errors?.to_ad_spend && (
                                                                <div className="custom_error_container" >
                                                                    <img src="/assets/images/info-button.png" alt="" />
                                                                    <p>The amount should be higher then { +row.from_ad_spend }</p>
                                                                </div>
                                                            ) }
                                                        </td>
                                                        <td >
                                                            <Select
                                                                id="commission_type"
                                                                name="commission_type"
                                                                defaultValue={commission_types.find(x => x.value == row.commission_type)}
                                                                options={commission_types}
                                                                onChange={changePPCSelectHandler(index)}
                                                                menuPortalTarget={document.body}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className='form-control'
                                                                type="text"
                                                                id='value'
                                                                step={row.commission_type == 1 ? 1 : 0.01}
                                                                onChange={(event) => changePPCFieldHandler(index, event)}
                                                                value={`${row.value}${row.commission_type == 2 ? '%' : ''}`}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <button
                                            type="button"
                                            className="btn btn-new"
                                            onClick={() => pushNewItem('ppc')}
                                            // disabled={isArchived}
                                        >
                                            <i className="fas fa-plus-square mr-1" />
                                            New
                                        </button>
                                    </div>

                                    <div className='form-group col-12 pt-1 mt-2'>
                                        <label className="mb-1 mr-2">
                                            DSP
                                        </label>
                                        <table className="table DIN2014 mt-0">
                                            <thead>
                                                <tr>
                                                    <th style={{width: "40px"}} className='text-center align-middle'>
                                                        <input
                                                            type="checkbox"
                                                            checked={!!formData.allDspChecked}
                                                            onChange={() => allCheckboxClickHandler('dsp')}
                                                        />
                                                    </th>
                                                    <th style={{width: "200px"}}>
                                                        From ad spend.....
                                                    </th>
                                                    <th style={{width: "200px"}}>
                                                        To ad spend.....
                                                    </th>
                                                    <th style={{width: "200px"}}>
                                                    Commission type
                                                    </th>
                                                    <th style={{width: "200px"}}>
                                                    Value
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dspData.map((row, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center align-middle">
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedItems.some(i => i === row.id)}
                                                                onChange={checkboxClickHandler.bind(null, row)}
                                                            />
                                                        </td>
                                                        <td style={{ position: 'relative', overflow: 'visible' }}>
                                                            <input
                                                                type="number"
                                                                className={`form-control ${ row.errors?.from_ad_spend ? 'invalid' : ''}`}
                                                                step={0.01}
                                                                id='from_ad_spend'
                                                                onChange={event => changeDSPFieldHandler(index, event)}
                                                                value={row.from_ad_spend}
                                                            />
                                                            { row.errors?.from_ad_spend && (
                                                                <div className="custom_error_container" >
                                                                    <img src="/assets/images/info-button.png" alt="" />
                                                                    <p>The amount should be higher then {+dspData[index-1].to_ad_spend}</p>
                                                                </div>
                                                            ) }
                                                        </td>
                                                        <td style={{ position: 'relative', overflow: 'visible' }}>
                                                            <input
                                                                className={`form-control ${  row.errors?.to_ad_spend ? 'invalid' : ''}`}
                                                                type="number"
                                                                step={0.01}
                                                                id='to_ad_spend'
                                                                onChange={event => changeDSPFieldHandler(index, event)}
                                                                value={row.to_ad_spend}
                                                            />
                                                            {  row.errors?.to_ad_spend && (
                                                                <div className="custom_error_container" >
                                                                    <img src="/assets/images/info-button.png" alt="" />
                                                                    <p>The amount should be higher then { +row.from_ad_spend }</p>
                                                                </div>
                                                            ) }
                                                        </td>
                                                        <td>
                                                            <Select
                                                                id="commission_type"
                                                                name="commission_type"
                                                                value={commission_types.find(x => x.value == row.commission_type)}
                                                                onChange={changeDSPSelectHandler(index)}
                                                                menuPortalTarget={document.body}
                                                                options={commission_types}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className='form-control'
                                                                type="text"
                                                                id='value'
                                                                step={row.commission_type == 1 ? 1 : 0.01}
                                                                onChange={(event) => changeDSPFieldHandler(index, event)}
                                                                value={`${row.value}${row.commission_type == 2 ? '%' : ''}`}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <button
                                            type="button"
                                            className="btn btn-new"
                                            onClick={() => pushNewItem('dsp')}
                                            // disabled={isArchived}
                                        >
                                            <i className="fas fa-plus-square mr-1" />
                                            New
                                        </button>
                                    </div>

                                    
                                    <div className='form-group col-12 pt-1 mt-2'>
                                        <label className="mb-1 mr-2">
                                            APN
                                        </label>
                                        <table className="table DIN2014 mt-0">
                                            <thead>
                                                <tr>
                                                    <th style={{width: "40px"}} className='text-center align-middle'>
                                                        <input
                                                            type="checkbox"
                                                            checked={!!formData.allApnChecked}
                                                            onChange={() => allCheckboxClickHandler('apn')}
                                                        />
                                                    </th>
                                                    <th style={{width: "200px"}}>
                                                        From ad spend.....
                                                    </th>
                                                    <th style={{width: "200px"}}>
                                                        To ad spend.....
                                                    </th>
                                                    <th style={{width: "200px"}}>
                                                    Commission type
                                                    </th>
                                                    <th style={{width: "200px"}}>
                                                    Value
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {apnData.map((row, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center align-middle">
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedItems.some(i => i === row.id)}
                                                                onChange={checkboxClickHandler.bind(null, row)}
                                                            />
                                                        </td>
                                                        <td style={{ position: 'relative', overflow: 'visible' }}>
                                                            <input
                                                                type="number"
                                                                className={`form-control ${ row.errors?.from_ad_spend ? 'invalid' : ''}`}
                                                                step={0.01}
                                                                id='from_ad_spend'
                                                                onChange={event => changeApnFieldHandler(index, event)}
                                                                value={row.from_ad_spend}
                                                            />
                                                            { row.errors?.from_ad_spend && (
                                                                <div className="custom_error_container" >
                                                                    <img src="/assets/images/info-button.png" alt="" />
                                                                    <p>The amount should be higher then {+dspData[index-1].to_ad_spend}</p>
                                                                </div>
                                                            ) }
                                                        </td>
                                                        <td style={{ position: 'relative', overflow: 'visible' }}>
                                                            <input
                                                                className={`form-control ${  row.errors?.to_ad_spend ? 'invalid' : ''}`}
                                                                type="number"
                                                                step={0.01}
                                                                id='to_ad_spend'
                                                                onChange={event => changeApnFieldHandler(index, event)}
                                                                value={row.to_ad_spend}
                                                            />
                                                            {  row.errors?.to_ad_spend && (
                                                                <div className="custom_error_container" >
                                                                    <img src="/assets/images/info-button.png" alt="" />
                                                                    <p>The amount should be higher then { +row.from_ad_spend }</p>
                                                                </div>
                                                            ) }
                                                        </td>
                                                        <td>
                                                            <Select
                                                                id="commission_type"
                                                                name="commission_type"
                                                                value={commission_types.find(x => x.value == row.commission_type)}
                                                                onChange={changeApnSelectHandler(index)}
                                                                menuPortalTarget={document.body}
                                                                options={commission_types}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className='form-control'
                                                                type="text"
                                                                id='value'
                                                                step={row.commission_type == 1 ? 1 : 0.01}
                                                                onChange={(event) => changeApnFieldHandler(index, event)}
                                                                value={`${row.value}${row.commission_type == 2 ? '%' : ''}`}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                            
                                    {update && (
                                        <>
                                            {loading || deleteLoading ? (
                                                <div className="spinner">Spinner</div>
                                            ) : (
                                            <>
                                                <div className="form-group col-12 d-flex pt-1 mt-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-new"
                                                        onClick={() => pushNewItem('apn')}
                                                    >
                                                        <i className="fas fa-plus-square mr-1" />
                                                        New
                                                    </button>

                                                    <button type="submit" onClick={submitHandler} className="btn btn-primary custom-btn mx-1" >Save</button>

                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={deleteHandler}
                                                        disabled={!selectedItems.length} 
                                                    >
                                                        Delete
                                                    </button>
                                                </div>                                               
                                            </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
        </>
    );
    };

export default ManufacturerSettings;
