import React, { useEffect, useState } from 'react';
import ModalForm from '../../../components/modal/ModalForm'
import BundledItemsFormModal from './BundledItemsFormModal'

const BundledItemsForm = ({ productData, refetch,slug, update }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState([]);
  const [itemId, setItem] = useState(null)

  const addRowHandler = () => {
    setItem(null)
    setModalOpen(true)
  };

  const edit = (id) => {
    setItem(id)
    setModalOpen(true)
  }

  useEffect(() => {
    [...productData.bundleItems].forEach((item, index) => {
        if(item.product === null) productData.bundleItems.splice(index, 1);
    });

    setFormData(productData.bundleItems)
  }, [productData])


  return (
    <form>
      <div className="overflow-auto">
        <table className="table mt-0">
          <thead>
            <tr>
              <th>PID</th>
              <th>GTIN</th>
              <th>Product name</th>
              <th>Product Details</th>
              <th>Product Contents</th>
              <th>Packaging unit</th>
              <th>Quantity</th>
              <th>Status</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {formData.map((row, index) => row.product != null && (
              <tr key={index} >
                <td>
                  {row.product.slug}
                </td>
                <td>
                  {row.product.gtin??'-'}
                </td>
                <td>
                  <a  style={{color: '#00488c'}} target="_blank" href={`/products/${row.product.slug}/edit`}>
                    {row.product.product_name}
                  </a>
                </td>

                <td>
                  {row.product.product_details}
                </td>

                <td>
                  {row.product.product_contents}
                </td>

                <td>
                  {row.product.packagingUnit.name}
                </td>

                <td>
                  {row.product_qty}
                </td>

                <td style={{ minWidth: '150px' }}>
                  {row.product.status ? (
                    <span className="status-badge status-badge-active">Active</span>
                  ) : (
                    <span className="status-badge status-badge-archived">Archived</span>
                  )}
                </td>
                <td className="align-middle">
                  <i onClick={() => edit(row)} style={{cursor: 'pointer'}} className="fas fa-edit"></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {modalOpen && (
          <ModalForm styles={{ top: 'unset', transform: 'translateY(-400px)' }} setModalOpen={setModalOpen}>
            <BundledItemsFormModal
              itemsCount={formData.length??0}
              itemId={itemId}
              product_parent_id={productData.product_parent_id} 
              setModalOpen={() => {setModalOpen(false); refetch();}}
            />
          </ModalForm>
        )}

          <div>
            <button
              type="button"
              className="btn btn-new"
              onClick={addRowHandler}
            >
              <i className="fas fa-plus-square mr-1" />
              New
            </button>
        </div>
      </div>
    </form>
  );
};

export default BundledItemsForm;
