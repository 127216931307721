import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';

import Routes from '../../lib/constants/routes';
import DetailsHeader from '../../components/details-header/DetailsHeader';
import ProductFormCard from './ProductFormCard';
import ProductInformationForm from './forms/ProductInformationForm';
import ProductCategorizationForm from './forms/ProductCategorizationForm';
import LogisticsInformationForm from './forms/LogisticsInformationForm';
import MarketplaceInformationForm from './forms/MarketplaceInformationForm';
import PricesForm, { fieldsConvertToCents } from './forms/PricesForm';
import CustomsInformationForm from './forms/CustomsInfromationForm';
import BundledItemsForm from './forms/BundledItemsForm';

import { getProduct, getProductSettings, me } from '../../services/api/queryService';
import BundleInformationForm from './forms/BundleInformation';
import VariationsForm from './forms/VariationsForm';
import ProductAttributes from './forms/ProductAttributes';
import PharmacyInformation from './forms/PharmacyInformation';
import { marketplace_id_typeS } from '../../lib/constants/fields';
import { updateSingleProduct } from '../../services/api/mutationService';
import { useDispatch } from 'react-redux';

const { localStorage } = window;


const processSkusAsins = (productMarketplaces) => {
  const processedSkusAsins = productMarketplaces;

  processedSkusAsins.sort((a, b) => {
    const anum = +a.id.split('-C')[1];
    const bnum = +b.id.split('-C')[1];
    if (anum < bnum) {
      return -1;
    }
    if (anum > bnum) {
      return 1;
    }
    return 0;
  });

  return processedSkusAsins.map(item => {
    item = { ...item, ...item.skuFullFilmentVendors };
    item.marketplace_id_type = marketplace_id_typeS.find(w => w.name === item.marketplace_id_type).id
    item.marketplace_number_id = item.marketplace_product_id;
    item.cid = item.product_child_id;
    delete item.marketplace_product_id;
    delete item.skuFullFilmentVendors;
    delete item.__typename;
    delete item.id;
    delete item.cid;
    return item;
  });
};

const EditProduct = ({ match, update, marketplacePermission, pricePermission, getProductTitle }) => {
  const { data: user } = useQuery(me);

  const { data: productData, refetch } = useQuery(getProduct, {
    variables: { slug: match.params.id }
  });
  const { data: productSettingsData } = useQuery(getProductSettings);
  const history = useHistory();
  const [showPriceSection, setShowPriceSection] = useState(false); 
  const [parentProduct, setParentProduct] = useState(false); 
  const [activeManufacturers, setActiveManufacturers] = useState([]);
  const [submitForm, setSubmitForm] = useState(false); 
  const [formData, setFormData] = useState(null); 
  const [hideScroll, setHideScroll] = useState(false); 
  const [errors, setErrors] = useState([]); 
  const dispatch = useDispatch()
  const wrapperRef = useRef()
  
  const [
    updateSingleProductMutation,
    { loading: updateProductLoading, error: updateError }
  ] = useMutation(updateSingleProduct);
  
  let selectData;
  let productPrices;

  if (productSettingsData) {
    selectData = JSON.parse(productSettingsData.productSettings);
    selectData.marketplaces.forEach(item => {
      if (typeof item.tax_rate === 'string') {
        item.tax_rate = JSON.parse(item.tax_rate);
      }
    });
  }

  useEffect(() => {
    const storageActiveManufacturers = JSON.parse(localStorage.getItem('active_manufacturers'));
    if(storageActiveManufacturers){
      setActiveManufacturers(storageActiveManufacturers);
    }
  }, []);

  window.addEventListener('activeManufacturerChange', (e) => {
      let aManufacturers = JSON.parse(localStorage.getItem('active_manufacturers'));
      setActiveManufacturers(aManufacturers);
  });

  useEffect(() => {
    if(productSettingsData && activeManufacturers) {
      if(activeManufacturers.length > 0){
        selectData.manufacturers = activeManufacturers;
      }
    }
  }, [activeManufacturers, selectData]);
  
  useEffect(() => {
    if (productData && productData.product) {

      const fieldsToParse = [
        'product_category_pharmacy',
        'product_category_hazmat',
        'product_category_electronics',
        'product_category_food',
      ];
      fieldsToParse.forEach(field => {
        if (productData.product[field] && typeof productData.product[field] === 'string') {
          productData.product[field] = productData.product[field].split(',')
        } else if (productData.product[field] === null) {
          productData.product[field] = [];
        }
      });
  
      setParentProduct(+productData.product.product_type_id === 8)
  
      productData.product.marketplacesInformation = processSkusAsins(
        productData.product.productMarketplaces
      );
      productPrices = productData.product.productPrices.map(item => {
        const updatedItem = { ...item };
        Object.keys(updatedItem).forEach(key => {
          if (fieldsConvertToCents[key]) {
            updatedItem[key] = +(updatedItem[key] / 100).toFixed(2);
          }
        });
        delete updatedItem.__typename;
        return updatedItem;
      });
      productData.product.customs.forEach(i => delete i.__typename);
      
      const ps = productData.product.manufacturer.commission_type === 'No commission' ? true : false
      setShowPriceSection(ps)

      localStorage.setItem('header_title', productData.product.product_name)
      let pckgName = ''
      if(+productData.product.product_type_id !== 8){
        pckgName = `| ${productData.product.packagingUnit?.name}`
      }

      getProductTitle(`${productData.product.product_name} ${pckgName}`)


      const requestData = {
        productInformation: {
          manufacturer_id: productData.product.manufacturer && productData.product.manufacturer.id,
          manufacturer_slug: productData.product.manufacturer && productData.product.manufacturer.slug,
          brand_id: productData.product.brand && productData.product.brand.id,
          status: productData.product.status,
          product_type_id: productData.product.type && productData.product.type.id,
          product_name: productData.product.product_name,
          product_details: productData.product.product_details,
          product_contents: productData.product.product_contents,
          packaging_unit_id: productData.product.packagingUnit && productData.product.packagingUnit.id,
          gtin: productData.product.gtin,
          manufacturer_product_id: productData.product.manufacturer_product_id,
          base_content_volume: Number(productData.product.base_content_volume, 2),
          base_unit: productData.product.base_unit,
          pzn: productData.product.pzn,
          seasonality: productData.product.seasonality,
          biozid: productData.product.biozid,
          inhaltsstoffe: productData.product.inhaltsstoffe,
          allergene: productData.product.allergene,
          lot_number_required: productData.product.lot_number_required,
          expiration_date: productData.expiration_date,
          attributes: productData.product.attributes && productData.product.attributes.length > 0 ? productData.product.attributes.split(',').map(x => ({id: x, name: x})) : []
        },
        productCategories: {
          product_category_pharmacy: productData.product.product_category_pharmacy??[],
          product_category_hazmat: productData.product.product_category_hazmat??[],
          product_category_electronics: productData.product.product_category_electronics??[],
          product_category_food: productData.product.product_category_food??[],
        },
        marketplaces: [...productData.product.marketplacesInformation],
        prices: productData.product.productPrices.map(item => {
          const updatedItem = { ...item };
          Object.keys(updatedItem).forEach(key => {
            if (fieldsConvertToCents[key]) {
              updatedItem[key] = +(updatedItem[key] / 100).toFixed(2);
            }
          });
          delete updatedItem.__typename;
          return updatedItem;
        }),
        logisticsInformation: {
          article_weight: productData.product.productLogistic
            ? productData.product.productLogistic.article_weight
            : undefined,
          article_width: productData.product.productLogistic
            ? productData.product.productLogistic.article_width
            : undefined,
          article_height: productData.product.productLogistic
            ? productData.product.productLogistic.article_height
            : undefined,
          article_depth: productData.product.productLogistic
            ? productData.product.productLogistic.article_depth
            : undefined,
          article_unit_volume: productData.product.productLogistic
            ? productData.product.productLogistic.article_unit_volume
            : undefined,
          article_packaging_type_id: productData.product.productLogistic
            ? productData.product.productLogistic.article_packaging_type_id
            : undefined,
          logistical_unit_weight: productData.product.productLogistic
            ? productData.product.productLogistic.logistical_unit_weight
            : undefined,
          logistical_unit_width: productData.product.productLogistic
            ? productData.product.productLogistic.logistical_unit_width
            : undefined,
          logistical_unit_height: productData.product.productLogistic
            ? productData.product.productLogistic.logistical_unit_height
            : undefined,
          logistical_unit_depth: productData.product.productLogistic
            ? productData.product.productLogistic.logistical_unit_depth
            : undefined,
          logistical_unit_volume: productData.product.productLogistic
            ? productData.product.productLogistic.logistical_unit_volume
            : undefined,
          packaging_unit_logistical: productData.product.productLogistic
            ? productData.product.productLogistic.packaging_unit_logistical
            : undefined,
          packaging_unit_per_eur_pallet: productData.product.productLogistic
            ? productData.product.productLogistic.packaging_unit_per_eur_pallet
            : undefined,
      
          logistics_ve_height : productData.product.productLogistic?.logistics_ve_height??0,
          logistics_ve_width : productData.product.productLogistic?.logistics_ve_width??0,
          logistics_ve_depth : productData.product.productLogistic?.logistics_ve_depth??0,
          logistics_ve_weight : productData.product.productLogistic?.logistics_ve_weight??0,
          logistics_ve_unit_volume : productData.product.productLogistic ? Math.round(
            (+productData.product.productLogistic?.logistics_ve_width *
              +productData.product.productLogistic?.logistics_ve_height *
              +productData.product.productLogistic?.logistics_ve_depth) /
              1000
          ) : undefined  ,
          fbm_size_id: productData.product.productLogistic ? productData.product.productLogistic.fbm_size_id : null,
          fba_size_id: productData.product.productLogistic ? productData.product.productLogistic.fba_size_id : null,
          shipping_unit_id: productData.product.productLogistic ? productData.product.productLogistic.shipping_unit_id : null
        },
        customsInformation: productData.product.customs ? productData.product.customs[0] : []
      }

      if(!formData?.requestData){
        setFormData({...productData.product, requestData: requestData})
      }else{
        const fdr = {...formData.requestData}
        fdr.marketplaces = [...productData.product.marketplacesInformation]
        fdr.prices = productData.product.productPrices.map(item => {
          const updatedItem = { ...item };
          Object.keys(updatedItem).forEach(key => {
            if (fieldsConvertToCents[key]) {
              updatedItem[key] = +(updatedItem[key] / 100).toFixed(2);
            }
          });
          delete updatedItem.__typename;
          return updatedItem;
        })

        setFormData({...productData.product, requestData: fdr})
      }

    }
  }, [productData])

  if (productData && productData.product) {

    const fieldsToParse = [
      'product_category_pharmacy',
      'product_category_hazmat',
      'product_category_electronics',
      'product_category_food',
    ];
    fieldsToParse.forEach(field => {
      if (productData.product[field] && typeof productData.product[field] === 'string') {
        productData.product[field] = productData.product[field].split(',')
      } else if (productData.product[field] === null) {
        productData.product[field] = [];
      }
    });


    productData.product.marketplacesInformation = processSkusAsins(
      productData.product.productMarketplaces
    );
    productPrices = productData.product.productPrices.map(item => {
      const updatedItem = { ...item };
      Object.keys(updatedItem).forEach(key => {
        if (fieldsConvertToCents[key]) {
          updatedItem[key] = +(updatedItem[key] / 100).toFixed(2);
        }
      });
      delete updatedItem.__typename;
      return updatedItem;
    });
    productData.product.customs.forEach(i => delete i.__typename);

  }

  const tabClickHandler = () => {
    history.push(`/listings/${match.params.id}/edit`);
  };

  const tabClickHandlerImages = () => {
    history.push(`/listings/${match.params.id}/images`);
  };

  const tabClickHandlerDocs = () => {
    history.push(`/listings/${match.params.id}/edit?tab=docs`);
  };

  const tabClickHandlerUnit = () => {
    history.push(`/unit-economics/${match.params.id}`);
  };

  const tabClickHandlerLogs = () => {
    history.push(`/product-logs/${match.params.id}`);
  };


  const updateFormCallback = useCallback(obj => {

    if(formData && formData.requestData){
      const fd = {...formData}

      if(Object.keys(obj).includes('marketplaces')){
        fd.marketplacesInformation = [...obj.marketplaces]
      }

      const rd = {...formData.requestData, ...obj}

      console.log('formData', formData)

    
      setFormData({...fd, requestData: rd})
    }

  }, [formData])

  const prepareCategoriesData = list => {
    const preparedFormData = {};

    Object.keys(list).forEach(key => {
      console.log(key, list[key])
      preparedFormData[key] =  Object.prototype.toString.call(list[key]) === "[object Object]" ? list[key].id : list[key]
    });

    return preparedFormData
  }

  console.log(formData?.requestData)

  const submitFormAsync = async () => {
    const reqData = formData.requestData
    reqData.productCategories = prepareCategoriesData(reqData.productCategories)

    console.log('productFormData', reqData)

    reqData.productInformation.attributes = Array.isArray(formData.requestData.productInformation.attributes) ? formData.requestData.productInformation.attributes.map(x => x.name).join(',') : formData.requestData.productInformation.attributes

    const marketplaceData = [...reqData.marketplaces]

    marketplaceData.map(x => {
      x.fee_category_id = x.fee_category_id??null
      delete x.marketplaceTypes
      delete x.marketplace
      delete x.fulfillment
      delete x.hasSale
      delete x.is_read_only
    })

    const pricesData = reqData.prices.map(item => {
      const updatedItem = { ...item };
      Object.keys(updatedItem).forEach(key => {
        if (fieldsConvertToCents[key]) {
          updatedItem[key] = +(+updatedItem[key] * 100).toFixed(2);
        }
      });
      delete updatedItem.marketplace
      delete updatedItem.__typename;
      return updatedItem;
    })

    setErrors([])


    const response = await updateSingleProductMutation({
        variables: { input: {
          product_parent_id: productData.product.product_parent_id,
          customsInformation: reqData.customsInformation,
          logisticsInformation: reqData.logisticsInformation,
          prices: [...pricesData],
          productCategories: reqData.productCategories,
          productInformation: reqData.productInformation,
          marketplaces: [...marketplaceData]
        }  }
    })

    if (response.data && response.data.updateSingleProduct.errors) {
      setErrors(JSON.parse(response.data.updateSingleProduct.errors))
      // let warningText = JSON.parse(response.data.updateProduct.warning);
      // warningText = warningText.join(";");
      // setErrorArray([...errorArray, warningText])

      const tc = Object.keys(JSON.parse(response.data.updateSingleProduct.errors)).length

      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'error',
          message: `There are ${tc} unsolved errors that need you attention`
        }
      });

      if(wrapperRef.current){
        wrapperRef.current.scrollTo(0, 0);
      }

    }else{
      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: `Recored was successfully `
        }
      });
      
      refetch()
      setSubmitForm(false)  
    }
    

  }

  const submit = async () => {
    setSubmitForm(true)    

    submitFormAsync()
  }

  return (
    <>
      {!productData || !formData ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <>
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">
                  {
                  !parentProduct ? (
                    <>
                      {productData.product.product_name} | {productData.product.packagingUnit?.name}
                    </>
                  ) : (
                    productData.product.product_name
                  )}
                  
                </h4>

                <div className="page-title-left">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">PIM</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={`${Routes.products.index}?tab=search`}>Products</Link>
                    </li>
                    <li className="breadcrumb-item active">{productData.product.product_name}</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* end page title  */}

          <DetailsHeader renderFor="products" data={productData.product} parentProduct={parentProduct} />

          {/* Tabs */}
          <div className="product-tabs product-tabs-custom col-10">
            <div
              className={`tab active`}
              onClick={null}
            >
              Information
            </div>
          
            <div
            className={`tab `}
            onClick={tabClickHandler}
            >
              Content
            </div>
            { user.me.user_type == 2 &&
              (<div
                className={`tab `}
                onClick={tabClickHandlerLogs}
                >
                Update log
              </div>)
            }

            <div
              className={`tab `}
              onClick={tabClickHandlerImages}
            >
              Images
            </div>
            
            <div className="tab-bar" />
          </div>

          <div className="sticky_buttons_wrapper">
            {updateProductLoading ? (
                <div className="spinner">Spinner</div>
            ) : (
              <>
              <button className="btn btn-primary px-3 mx-2" onClick={submit}>
                Save
              </button>
              <button className="btn btn-secondary px-3 mx-2">
                Discard
              </button>
              </>
            )}

          </div>

          <div ref={wrapperRef} className={`singleProductWrapper ${hideScroll ? 'ov-hid' : ''}`}>

          <ProductFormCard id="productInformation" formPreOpen={true}>
            { productSettingsData ? (
              <ProductInformationForm
				        updateFormData={updateFormCallback}
                submitForm={submitForm}
                selectData={selectData}
                productData={productData.product}
                refetch={refetch}
                update={update}
                errors={errors}
                req="updateProduct"
              />
              ) : (
                <div className="spinner absolute">Spinner</div>
              ) }
          </ProductFormCard>
          {!parentProduct && (

            <ProductFormCard id="productCategorization" formPreOpen={true}>
            { productSettingsData ? (
              <ProductCategorizationForm
                updateFormData={updateFormCallback}
                selectData={selectData}
                productData={productData.product}
                refetch={refetch}
                update={update}
                parentProduct={parentProduct}
                submitForm={submitForm}
                errors={errors}
              />
              ) : (
                <div className="spinner absolute">Spinner</div>
              ) }
            </ProductFormCard>
          )}

          {!parentProduct && (
            <ProductFormCard id="logisticsInformation" formPreOpen={true}>
            { productSettingsData ? (
              <LogisticsInformationForm
                updateFormData={updateFormCallback}
                selectData={selectData}
                productData={productData.product}
                refetch={refetch}
                update={update}
                errors={errors}
              />
              ) : (
                  <div className="spinner absolute">Spinner</div>
                ) }
            </ProductFormCard>
          )}
          <ProductFormCard id="marketplaceInformation" hasTable={true} formPreOpen={true}>
          { productSettingsData ? (
            <MarketplaceInformationForm
              updateFormData={updateFormCallback}
              selectData={selectData}
              productData={formData}
              refetch={refetch}
              update={update}
              parentProduct={parentProduct}
              marketplacePermission={marketplacePermission}
              submitForm={submitForm}
              errors={errors}
              toggleOverflowScroll={() => setHideScroll(!hideScroll)}
            />
            ) : (
                <div className="spinner absolute">Spinner</div>
              ) }
          </ProductFormCard >
          { !parentProduct && (
              <ProductFormCard id="prices" hasTable={true} disabled={showPriceSection} formPreOpen={true}>
              { productSettingsData ? (
                <PricesForm
                  updateFormData={updateFormCallback}
                  selectData={selectData}
                  productData={productData.product}
                  productId={productData.product.product_parent_id}
                  productPrices={productPrices}
                  refetch={refetch}
                  update={update}
                  errors={errors}
                  pricePermission={pricePermission}
                />
                ) : (
                    <div className="spinner absolute">Spinner</div>
                  ) }
              </ProductFormCard>
          )}
          {!parentProduct && (

            <ProductFormCard id="customsInformation" hasTable={true} formPreOpen={true}>
            { productSettingsData ? (
              <CustomsInformationForm
                updateFormData={updateFormCallback}
                selectData={selectData}
                productData={productData.product}
                refetch={refetch}
                update={update}
                errors={errors}
              />
              ) : (
                  <div className="spinner absolute">Spinner</div>
                ) }
            </ProductFormCard>
          )}

          {/* {productData.product && productData.product.type && productData.product.type.id && productData.product.type.id == 7 && ( */}
          {/* Show bundles when article packaging type of Soft Bundle */}
          {formData && formData.requestData && formData.requestData.logisticsInformation && formData.requestData.logisticsInformation.article_packaging_type_id == 2 && (
            <ProductFormCard id="bundledItems" hasTable={true} formPreOpen={true}>
              { productSettingsData ? (
              <BundledItemsForm 
				       updateFormData={updateFormCallback}
                selectData={selectData}
                productData={productData.product}
                productId={productData.product.product_parent_id}
                productPrices={productPrices}
                refetch={refetch}
                update={update}
              />
              ) : (
                <div className="spinner absolute">Spinner</div>
              ) }
            </ProductFormCard>
          )}

          {productData.product && productData.product.hasParentProducts.length > 0 && (
            <ProductFormCard id="bundleInformation" hasTable={true} formPreOpen={true}>
              { productSettingsData ? (
              <BundleInformationForm 
				       updateFormData={updateFormCallback}
                selectData={selectData}
                productData={productData.product}
                productId={productData.product.product_parent_id}
                productPrices={productPrices}
                refetch={refetch}
                update={update}
              />
              ) : (
                <div className="spinner absolute">Spinner</div>
              ) }
            </ProductFormCard>
          )}

          {parentProduct && (
            <ProductFormCard id="variations" hasTable={true} formPreOpen={true}>
            { productSettingsData ? (
              <VariationsForm 
				       updateFormData={updateFormCallback}
                productData={productData.product}
                productId={productData.product.product_parent_id}
                refetch={refetch}
                update={update}
              />
              ) : (
                  <div className="spinner absolute">Spinner</div>
                ) }
            </ProductFormCard>
          )}

          {productData.product.parentProduct && (
            <ProductFormCard id="attributes" hasTable={true} isAttribute={true} formPreOpen={true}>
            { productSettingsData ? (
              <ProductAttributes 
				       updateFormData={updateFormCallback}
                productData={productData.product}
                productId={productData.product.product_parent_id}
                refetch={refetch}
                update={update}
              />
              ) : (
                  <div className="spinner absolute">Spinner</div>
                ) }
            </ProductFormCard>
          )}
          </div>
        </>
      )}
    </>
  );
};

export default EditProduct;
