import React, { memo } from 'react';

import { STATUSES, IMPORT_STATUSES, PRODUCT_STATUSES, DOCUMENT_UPLOAD_STATUSES } from '../../../lib/constants/fields';

/** Styles */
import './Td.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Td = ({ field, value, to = '', onClick, moduleName, item, notClickable, isListing }) => {
  if (field === 'commission_percentage' || field === 'margin_percentage') {
    value += '%';
  }

  
  const types = [
      { label: 'EMS', value: 1 },
      { label: 'APS', value: 2 }
  ]
  
  if (field === 'status' || field === 'advertising_status') {
    let badge = PRODUCT_STATUSES[value];
    if (moduleName === 'importJobs') {
      badge = IMPORT_STATUSES[value];
    }
    if( moduleName === 'DocumentUploads'){
      badge = DOCUMENT_UPLOAD_STATUSES[value];

    }
    return <td>{badge}</td>;
  }

  if (field === 'type' && moduleName == 'manufacturers') {
    return <td>{types.find(x => x.value == value).label}</td>;
  }

  switch (typeof value) {
    case 'boolean':
      value = value ? 'active' : 'disabled';
      break;

    case 'string':
      if(isListing && field === 'marketplace_name'){
        let img = 'de'
        if(value.split('.').length > 1){
          img = value.split('.')[1]
        }
        value = <li className="_li" key={value}> 
            <img className='flag' src={`/assets/images/flags/${img}.svg`} alt='' />
            {value} 
          </li>;

      }else if (field === 'data') {
        value = Object.keys(JSON.parse(value)[0]).map(item => {
          return <li key={item}> {item} </li>;
        });

        value = <ul>{value}</ul>;
      }
      break;

    case 'object':
      if (value) {
        console.log('never ');
        value = Object.keys(value).map((child, i) => {
          if (child !== '__typename' && i === 1) {
            return (
              <p key={i}>
                <strong className="text-capitalize">{child} :</strong> {value[child]}
              </p>
            );
          } else {
            return null;
          }
        });
      }

      break;

    default:
      break;
  }

  const isLink = field === 'name' || field === 'product_name';
  const pageArr = ['manufacturers', 'products', 'listings'];
  const itemUrl = id => {
    if(pageArr.includes(moduleName)){
      if(isListing){
        return `/listings/${item.slug}/edit`;
      }
     return `/${moduleName}/${item.slug}/edit`;
    } else if (moduleName !== 'importJobs') {
     return `/${moduleName}/${id}/edit`;
    } else {
     return `/products/import/${id}`;
    }
  };

  return (
    <td className={`${notClickable ? '' : 'cursor-pointer'} ${isLink ? 'td-link' : ''}`} onClick={onClick}>
      {notClickable ? (
        field === 'embeddedLocation' ? (
          <Link target="_blank" style={{ color: '#00488c' }} to={'/' + value}><span>{value}</span></Link>
        ) : (
          <span>{value}</span>
        )
      ) : (
        <Link style={{color: 'inherit', display: 'block'}} to={itemUrl(item.id || item.product_parent_id)}>
          {value}
        </Link>
      )}
    </td>
  );
};

export default memo(Td);
